export type ConfType = {
  downloadPDFAndCSV: boolean;
  askIsCardLostOrStolen: boolean;
  callCenterNumber: string;
  internationalCallCenterNumber: string;
  supportEmail: string;
  routesBlackList: string[];
  googlePlay?: string;
  appStore?: string;
  defaultCurrency?: string;
};

export type ConfItemType = {
  [key in keyof ConfType]?: ConfType[key];
};

const configuration: ConfType = {
  downloadPDFAndCSV: true,
  askIsCardLostOrStolen: true,
  callCenterNumber: '+44 (0) 203 773 1922',
  internationalCallCenterNumber: '+44 (0) 203 773 1922',
  supportEmail: 'info@pannovate.com',
  routesBlackList: [],
  googlePlay: 'https://play.google.com/store/apps',
  appStore: 'https://www.apple.com/app-store/'
};

export default configuration;
